import React from "react"
import Layout from "../components/Layout";
import Sections from '../components/Sections';
import Seo from "../components/Seo2";
import Page from "../components/Page";
import { graphql } from 'gatsby';

const IndexPage = props => {
	const { data } = props; //, children, location, navigate, pageContext, pageResource, path, uri } = props;
	// console.log("Page", props);
	const { dataJson } = data;
	const { meta, page, sections } = dataJson;
	return <Layout>
		<Seo {...meta} />
		<Page {...page} />
		<Sections sections={sections} />
	</Layout>
}

export const pageQuery = graphql`
	query($canonical: String!) {
		dataJson(meta: { canonical: { eq: $canonical } }) {
			links {
				text
				url
				icon
			}
			logo {
				image
				text
				url
			}
			meta {
				title
				description
				canonical
			}
			page {
				heading
				body
			}
			sections {
				section {
					color
				}
				template
				heading
				html
				cards {
					heading
				}
				background
				columns
				dotted
				imageUrl
				imageFallbackExtension
				imageBottom
				imagePlacement
				color
				href
				text
				texts
				imageSrc
				imageSize
				subHeading
				src
				successMessage
			}
		}
	}
`
export default IndexPage
